.container {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
}

.title {
    color: #0891c1;
}

.subtitle {
    background-color: #0891c1;
    padding: 12px;
    color: white;
    border-radius: 5px;
    margin-top: -13px;
}

.triangle {
    color: #0891c1;
    width: 35px;
    height: 35px;
    margin-top: -15px;
}

.planes {
    background-color: black;
    border-radius: 10px; 
    padding: 0px 10px 3px;
    border: 1px;    
}

.circle {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #0891c1;
    margin-bottom: 20px;
}

.circlein {
    height: 130px;
    width: 130px;
    background-color: black;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 2px;
}

.valor {
    color: white;
    padding-top: 40px;
    font-size: 30px;
}

.card {
    margin-bottom: 100px;
}

.lista {
    height: 40px;
    padding: 20px;
}

.cardlist {
    background-color: white;
    width: 98%;
    border-radius: 7px;
}

