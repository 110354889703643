.background-header {

    background: rgb(0,0,0);
    background: linear-gradient(
        90deg,
        rgba(0,0,0,0.8389458272327965) 20%,
        rgba(8,145,193,1) 50%,
        rgba(249,159,61,1) 75%
    );
}

@media  screen and (max-width: 600px){
    .background-header{
        background: rgb(0,0,0);
        background: linear-gradient(
            90deg,
            rgba(0,0,0,0.8389458272327965) 20%,
            rgba(0,0,0,1) 50%,
            rgba(0,0,0,1) 75%
        );
    }
    
}

.header {
    height: 100px;
}

@media screen and (max-width: 331px) {
    .icono{
        display:none;
    }       
}

@media screen and (max-width: 768px) {
    .logo{
        display:none;
    }       
}

.social-networks {
    width: 30px;
    height: 30px;
}

.logo {
    display: flex;
}

.gion {
    background-color: #0891c1;
    height: 2px;
    width: 100px;
}

