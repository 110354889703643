
.info {
    text-align: center;
}

.icon {
    height: 100px;
    width: 100px;
    color: #f99f3d !important;
}

.triangle {
    color: #0891c1;
    width: 30px;
    height: 30px;
    margin-bottom: -10px;
}

.title {
    background-color: #0891c1;
    padding: 12px;
    color: white;
    border-radius: 5px;
}



.text {
    padding: 10px;
}