.headerSales {
    background: rgb(249, 159, 61);
    background: linear-gradient(90deg, rgba(249, 159, 61, 1) 15%, rgba(29, 146, 182, 1) 85%);
    height: 250px;
    width: 90%;
    margin: 2% 5%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleSales {
    height: 200px;
    width: 200px;
    background-color: white;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.circleInSales {
    height: 180px;
    width: 180px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    background: rgb(8,145,193);
    background: linear-gradient(90deg, rgba(8,145,193,1) 0%, rgba(249,159,61,1) 100%);
}

.carSales {
    color: white;
    height: 60%;
    width: 60%;
}

.circleFaPlus {
    position: absolute;
    z-index: 100;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: -80px;
    margin-left: 80px;
    background: rgb(154,154,113);
    background: linear-gradient(90deg, rgba(154,154,113,1) 0%, rgba(210,157,83,1) 100%);
}

.circleInFaPlus {
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 5px;
}

.faPlus {
    position: absolute;
    z-index: 100;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 5px;
    color: #B89C61;
}

.headerFormSelect {
    width: 60%;
    margin-left: 20%;
}

@media screen and (max-width: 600px) {
    .headerFormSelect {
        width: 90%;
        margin-left: 5%;
    }      
}
