.contwhatsapp {
    position: fixed;
    bottom: 30px;
    right: 0%;
}

.clientes {
    position: fixed;
    bottom: 120px;
    right: 0%;
}

.whatsapp {
    height: 50px;
    width: 50px;
    color: rgb(6, 240, 6);
}

.acceso {
    height: 50px;
    width: 50px;
    color: blue;
}